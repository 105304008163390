import React, { useState, useEffect } from "react";
import CategoryTable from "./Table/CategoryTable";
import { useCurrentRoute } from "react-navi";
import api from "../../api/judgeController";
import useCategories from "../../hooks/useCategories";
//https://github.com/tannerlinsley/react-table/blob/master/README.md

const dashboard_text = {
  en: {
    nma: {
      welcome: "Welcome",
      intoText:
        "Welcome to the National Magazine Awards Judging platform. To get started, click on any of your assigned categories below to see its submissions.",
      noSubmissions:
        'You do not have any submissions assigned to you. If you believe this is a mistake, please contact us at <a href="mailto:staff@magazine-awards.com">staff@magazine-awards.com</a>.',
    },
    dpa: {
      welcome: "Welcome",
      intoText:
        "Welcome to the Digital Publishing Awards Judging platform. To get started, click on any of your assigned categories below to see its submissions.",
      noSubmissions:
        'You do not have any submissions assigned to you. If you believe this is a mistake, please contact us at <a href="mailto:info@digitalpublishingawards.ca">info@digitalpublishingawards.ca</a>.',
    },
    b2b: {
      welcome: "Welcome",
      intoText:
        "Welcome to the NMA: B2B Judging platform. To get started, click on any of your assigned categories below to see its submissions.",
      noSubmissions:
        'You do not have any submissions assigned to you. If you believe this is a mistake, please contact us at <a href="mailto:staff@magazine-awards.com">staff@magazine-awards.com</a>.',
    },
  },
  fr: {
    nma: {
      welcome: "Bienvenue",
      intoText:
        "Veuillez cliquer sur la catégorie qui vous a été assignée pour voir et télécharger les candidatures, et saisir vos notes.",
      noSubmissions:
        'You do not have any submissions assigned to you. If you believe this is a mistake, please contact us at <a href="mailto:staff@magazine-awards.com">staff@magazine-awards.com</a>.',
    },
    dpa: {
      welcome: "Bienvenue",
      intoText:
        "Veuillez cliquer sur la catégorie qui vous a été assignée pour voir et télécharger les candidatures, et saisir vos notes.",
      noSubmissions:
        'You do not have any submissions assigned to you. If you believe this is a mistake, please contact us at <a href="mailto:info@digitalpublishingawards.ca">info@digitalpublishingawards.ca</a>.',
    },
    b2b: {
      welcome: "Bienvenue",
      intoText:
        "Veuillez cliquer sur la catégorie qui vous a été assignée pour voir et télécharger les candidatures, et saisir vos notes.",
      noSubmissions:
        'You do not have any submissions assigned to you. If you believe this is a mistake, please contact us at <a href="mailto:staff@magazine-awards.com">staff@magazine-awards.com</a>.',
    },
  },
};

function Dashboard({ lang, org, user, year }) {
  console.log(">>>>Dashboard() ", { lang, org, user, year });
  const { categories, loading: loadingCategories } = useCategories({
    org,
    lang,
    user,
    year,
  });

  return (
    <div className={"cols padded-row"}>
      <div className="col m">
        <h2 id="submission_information_intro">
          {dashboard_text[lang.toLowerCase()][org.toLowerCase()].welcome},{" "}
          {/*{user.name}*/}
        </h2>
        <p className="intro-text">
          {dashboard_text[lang.toLowerCase()][org.toLowerCase()].intoText}
        </p>
        <div className="dashboard-categories">
          {loadingCategories ? (
            <div>Loading...</div>
          ) : (
            <>
              {categories.length === 0 && (
                <div dangerouslySetInnerHTML={{__html:dashboard_text[lang.toLowerCase()][org.toLowerCase()].noSubmissions}}/>
              )}
              {categories.length > 0 && (
                <CategoryTable {...{ categories, org, lang }} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import React, { useState } from "react";
import { NotFoundBoundary } from "react-navi";
import PageNotFound from "./404";
import "../../styles/judging.scss";
import nmaf_logo from "../../static/logo-desktop.png";

const layout_text = {
  en: {
    navigation: {
      logout: "Logout",
      // your_submission: 'Your Submissions',
      // submit_new_btn: 'Submit New Entry',
      categories: "Categories",
    },
    footer: {
      questions_cta: (
        <>
          Questions?{" "}
          <a href="mailto:staff@magazine-awards.com">
            Click here to contact us
          </a>
        </>
      ),
      copyright: "© 2023 National Media Awards Foundation",
    },
  },
  fr: {
    navigation: {
      logout: "Se déconnecter",
      // your_submission: 'Vos candidatures',
      // submit_new_btn: 'Soumettre une candidature',
      categories: "Catégories",
    },
    footer: {
      questions_cta: (
        <>
          Questions?{" "}
          <a href="mailto:staff@magazine-awards.com">Contactez notre équipe</a>
        </>
      ),
      copyright: "© 2023 Fondation des prix pour les médias canadiens",
    },
  },
};

const LanguageSubMenu = ({
  subMenuClass,
  showLogout,
  showLangDrop,
  lang,
  toggleLangDrop,
  changePageLanguage,
  handleLogout,
  org,
}) => {
  return (
    <ul className={`sub-menu ${subMenuClass}`}>
      <li
        className={`page-tool-dropdown ${org.toLowerCase()} ${
          showLangDrop ? "show-drop" : "hide-drop"
        }`}
      >
        <ul className="dropdown-list">
          {!showLangDrop ? (
            <li
              className={"language-option " + (lang === "en" ? "active" : "")}
              onClick={toggleLangDrop}
            >
              English
            </li>
          ) : (
            <li
              className={"language-option"}
              onClick={(e) => changePageLanguage("en")}
            >
              English
              <span className={"select-line"} />
            </li>
          )}
          {!showLangDrop ? (
            <li
              className={"language-option " + (lang === "fr" ? "active" : "")}
              onClick={toggleLangDrop}
            >
              Français
            </li>
          ) : (
            <li
              className={"language-option"}
              onClick={(e) => changePageLanguage("fr")}
            >
              Français
              <span className={"select-line"} />
            </li>
          )}
          <li onClick={toggleLangDrop}>
            <span
              className={`sprite icon drop-arrow ${
                org === "DPA" ? "white" : "black"
              } inline`}
            />
          </li>
        </ul>
      </li>
      {showLogout && (
        <li className={`logout ${org.toLowerCase()}`} onClick={handleLogout}>
          {layout_text[lang].navigation.logout || 'Logout'}
        </li>
      )}
    </ul>
  );
};

function Layout({
  children,
  authService,
  lang,
  setLang,
  org,
  isAuthenticated,
}) {
  const [showLangDrop, setShowLangDrop] = useState(false);
  const [showMenu, setShowMenu] = useState(isAuthenticated);
  if (isAuthenticated !== showMenu) setShowMenu(isAuthenticated);

  function toggleLangDrop() {
    setShowLangDrop((prev) => !prev);
  }

  function changePageLanguage(locale) {
    if (lang !== locale) setLang(locale);
    toggleLangDrop();
  }

  const handleLogout = async (e) => await authService.logout(()=>window.location.reload(false));

  return (
    <div className={`body-wrapper ${org}`}>
      <header className="clearfix">
        <div className="page-tools">
          <LanguageSubMenu
            subMenuClass={showMenu && "solo-menu"}
            showLogout={showMenu}
            org={org}
            showLangDrop={showLangDrop}
            lang={lang}
            toggleLangDrop={toggleLangDrop}
            changePageLanguage={changePageLanguage}
            handleLogout={handleLogout}
          />
        </div>
      </header>
      <div id="logo_section">
        <img
          src={nmaf_logo}
          alt="Logo des prix d’excellence en publication numérique"
        />
      </div>

      <main>
        <NotFoundBoundary render={PageNotFound}>
          <section id="judging_form">
            <div className="judging-wrap">
              <div className="judging-container">
                <div className="judging-inner">{children}</div>
              </div>
            </div>
          </section>
        </NotFoundBoundary>
      </main>

      <footer>
        <div className="footer-info">
          <p style={{ marginBottom: 0 }}>
            {layout_text[lang.toLowerCase()].footer.questions_cta}
          </p>
        </div>
        {layout_text[lang.toLowerCase()].footer.copyright}
      </footer>
    </div>
  );
}
export default Layout;

import React from "react";

function PageNotFound() {
  return (
    <div className="Layout-error">
      <h1>404 - Not Found</h1>
    </div>
  );
}

export default PageNotFound;

import React, { useCallback, useState } from "react";
import {
  Paper,
  Card,
  Collapse,
  Button,
  Typography,
  Grid,
  Avatar,
} from "@material-ui/core";
import {
  OpenInBrowser as OutIcon,
  CloudDownload as DownloadIcon,
} from "@material-ui/icons";

const JudgeEntryData = (props) => {
  const { title, value, type } = props;

  const JudgeEntryDataValue = ({ type, value }) => {
    switch (type) {
      case "upload":
        return (
          <Button href={value} target={"_blank"} startIcon={<DownloadIcon />}>
            Download
          </Button>
        );
      default:
        return value;
    }
  };

  return (
    <>
      <Typography variant={"body2"} color={"textSecondary"}>
        {title}
      </Typography>
      <Typography variant={"body1"} paragraph>
        <JudgeEntryDataValue {...props} />
      </Typography>
    </>
  );
};

function JudgeEntryCard(props) {
  const { title, fields } = props;
  console.log("JudgeEntryCard()", props);
  const uploadFields = fields.filter(
    (field) =>
      field.type === "upload" &&
      field.title.toLowerCase().indexOf("mandate") < 0 &&
      field.title.toLowerCase().indexOf("editorial") < 0
  );
  const linkFields = fields.filter(
    (field) =>
        (field.type === "text" || field.type === "longtext" ) &&
      (field.title.toLowerCase().indexOf("url") >= 0 ||
        field.title.toLowerCase().indexOf("link") >= 0)
  );
  // const [expanded, setExpanded] = useState(defaultExpanded);
  const FormButtons = useCallback(() => {
    const uploadButtons = uploadFields.map((field, idx) => (
      <Button
        size={"small"}
        color={"primary"}
        href={field.value}
        target={"_blank"}
        startIcon={<DownloadIcon />}
        variant={"outlined"}
      >
        Download File {idx + 1} of {uploadFields.length}
      </Button>
    ));

    const linkButtons = linkFields.map((field, idx) => (
      <Button
        size={"small"}
        color={"primary"}
        href={field.value}
        target={"_blank"}
        startIcon={<OutIcon />}
        variant={"text"}
      >
        View Link {idx + 1} of {linkFields.length}
      </Button>
    ));

    return [...linkButtons, ...uploadButtons];
  }, [uploadFields, linkFields]);

  return (
    <div>
      <Grid spacing={1} container wrap={"nowrap"}>
        <Grid item>
          {uploadFields.length > 0 && (
            <Avatar
              style={{
                height: "80px",
                width: "80px",
              }}
              alt={title}
              src={`https://nmafpublic.s3.amazonaws.com/${uploadFields[0].thumbnail}`}
            />
          )}
        </Grid>
        <Grid item>
          <Typography variant={"body1"} paragraph>
            <strong>{title}</strong>
          </Typography>
          <FormButtons />
        </Grid>
      </Grid>
    </div>
  );
}

export default JudgeEntryCard;

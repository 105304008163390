import { client } from "./client";

const getCategory = async (data) => {
  try {
    const { categoryId } = data;
    const req = await client.get("/judge/category/", {
      params: {
        categoryId,
      },
    });
    console.log("getCategory", req);
    if (req.status === 200) return req.data;
    throw new Error("Could not fetch Category error");
  } catch (e) {
    console.log("Could not fetch cat", e.message);
    return {};
  }
};

const getCategories = async (data) => {
  console.log("getting categories...", data);
  try {
    const { organization, lang, year } = data;
    const req = await client.get("/judge/category/all", {
      params: { organization, lang, year },
    });
    if (req.status === 200) return req.data;
    return {error: new Error(`Could not fetch Categories error. (${req.status})`)}
  } catch (e) {
    console.log("Could not fetch cats", e.message);
    return [];
  }
};

export default {
  getCategory,
  getCategories,
};

import React, { useState, useEffect } from "react";
import { useNavigation } from "react-navi";
// import score from "../utils/score"

function Score({ entry }) {
  //TODO Do i need to check authService.isAuthenticated() --> Program may auto detect and return to login. Might need to be check intraservice (i.e. sending/receiving new data)
  return (
    <div className={"cols padded-row"}>
      <div className="col m">
        <h2 id="submission_title">
          <span>{entry.favourite ? `* ` : ``}</span>
          {entry.title}
        </h2>
        <p id="submission_publisher">{entry.publication}</p>

        <div className="submission-body-wrap">
          <div id="submission_body">
            <ul className="submission-info">
              {entry.fields.map((field, i) => (
                <li key={`entry-value-${i}`}>
                  <span className="field-label">{field.label}</span>{" "}
                  {field.value}
                </li>
              ))}
            </ul>
          </div>
          <div id="submission_file">
            {entry.media.map((media, i) => {
              switch (media.type) {
                case "pdf":
                  return (
                    <object
                      width="600"
                      height="500"
                      data={`${media.url}?#scrollbar=0&toolbar=0&navpanes=0&view=FitH`}
                      type="application/pdf"
                    >
                      <embed
                        src={`${media.url}?#scrollbar=0&toolbar=0&navpanes=0&view=FitH`}
                        type="application/pdf"
                      />
                    </object>
                  );
                case "image":
                  return <p>TODO: IMAGE</p>;
                  break;
                default:
                  return <p>Error: Could not determine media type.</p>;
              }
            })}
          </div>
          <div id="submission_image"></div>
        </div>
      </div>

      <div className="col s">
        <div className="text-right">
          <a className="btn table-btn" href="/judge/submissions">
            <strong>&#8592;</strong> All Submissions
          </a>
        </div>
        <br />
        <br />
        <h3>Score Card</h3>
        <ul id="scoring-list" className="">
          {entry.score.map((score, i) => (
            <li key={`entry-score-${i}`} className="input-group select-wrap">
              <label htmlFor={`score_${score}`}>{score.title}</label>
              <select
                defaultValue={score.value === null ? -1 : score.value}
                className="score-change"
                id={`score_${score}`}
              >
                <option value={-1}>Not Scored</option>
                {Array.from(
                  { length: Math.round(score.max - score.min) },
                  (k, v) => v + score.min
                ).map((val, i) => (
                  <option key={`score-option-${i}`} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </li>
          ))}
        </ul>
        <p className="instruction-label">Scoring Instructions:</p>
        <p className="instructions">
          Select a score from each drop-down list above. Scores are saved
          automatically as you select them.
        </p>
      </div>
    </div>
  );
}

export default Score;

import { useState, useEffect } from "react";
import api from "../api/category";

function useCategories({ org, lang, user, year }) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      setLoading(true);
      api
        .getCategories({ organization: org, lang, year })
        .then((data) => {
          try {
            setCategories([...data]);
            setLoading(false);
          } catch (e) {
            // console.log("Use Categories Load Error:", e.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("Use Categories Load Failure", e);
        });
      
    }
  }, [org, lang, user]);

  return { categories, loading };
}

export default useCategories;

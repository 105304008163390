import React, { useState } from "react";
import InputGroup from "../Judge/InputGroup";
import {Typography} from "@material-ui/core";
import {useNavigation} from "react-navi";

const ResetPassword = ({ authService, lang, org }) => {
  const [sendingForm, setSendingForm] = useState(false);
  const [formSuccess,setFormSuccess] = useState('')
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });

    let navigation = useNavigation();

  const resetPassword = async () => {
    if (formData.email.trim().length === 0)
      setFormError("Please enter an email.");
    //TODO: Validate for email string
    else {
      setFormError("");
      const doPasswordReset = await authService.resetPassword({
        ...formData,
        lang,
        org,
      });
        if(!doPasswordReset) setFormError('There was an error resetting your password. Please try again later')
        else setFormSuccess('Check your email — a password reset email has been sent to the email address')
    }
    // setSendingForm(false);
    return false;
  };

  return (
    <form>
        {formSuccess && <div className={"success"}>
            <p className={"success"}>{formSuccess}</p>
        </div>}
        {/*<Alert*/}
      {formError.length > 0 && (
        <div className={"error"}>
          <p className={"error"}>{formError}</p>
        </div>
      )}

      <Typography variant={'h1'}>Reset Your Password</Typography>
      <Typography variant={'body1'}>Enter your email address below and we'll send you an email with instructions on how to reset your password.</Typography>
      <br/>
        <InputGroup
        id="email"
        onInputChange={(e) => {
          e.persist();
          setFormData((prev) => ({ ...prev, email: e.target.value }));
        }}
        value={formData.email}
        type="email"
        label="Your email"
        name="email"
        placeholder="your@email.com"
      />

      <div className="text-right btn-wrap">
          <button
              id="go_back"
              type="button"
              className="btn btn-secondary"
              onClick={(e)=>{
                  e.preventDefault()
                  navigation.navigate(`/login`);
              }}
          >
              Back to Login
          </button>
        <button
          id="submit"
          type="button"
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            if (!sendingForm) {
              setSendingForm(true);
              resetPassword();
            }
          }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ResetPassword;

import React, { useState, useEffect, useCallback } from "react";
import { matchSorter } from "match-sorter";
import {
  useSortBy,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import {
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import {
  CloudDownload as DownloadIcon,
  Backspace as ClearIcon,
  Search as SearchIcon,
  FavoriteBorder,
  Tab,
} from "@material-ui/icons";
import score from "../../../utils/score";
import JudgeEntryCard from "./JudgeEntryCard";

const scoreCardTable_text = {
  en: {
    status: {
      noTitle: "(No Title)",
      noPublisher: "(No Publisher Listed)",
    },
    btn: {
      link: "Visit Link",
      download: "Download File",
    },
    table: {
      header: {
        submission: "Submission",
        magazine_nma: "Magazine",
        magazine_dpa: "Publication",
        magazine_b2b: "Magazine",
        score: "Total Score",
      },
      body: {
        sortInstruction:
          "To sort, please click a header: 🤍, Submission, Magazine or Score",
        editorialMandate: "Editorial Mandate",
        readMore: "Read More",
        readLess: "Read Less",
        ofSub: "of",
      },
    },
  },
  fr: {
    status: {
      noTitle: "(Pas de titre)",
      noPublisher: "(Pas de publication)",
    },
    btn: {
      link: "Visitez le lien",
      download: "Téléchargez un fichier",
    },
    table: {
      header: {
        submission: "Soumission",
        magazine_nma: "Magazine",
        magazine_dpa: "Publication",
        magazine_b2b: "Magazine",
        score: "Note",
      },
      body: {
        sortInstruction:
          "Cliquez sur l'entête d'une colonne pour trier les candidatures en fonction des notes, en ordre alphabétique, ou selon vos favoris.",
        editorialMandate: "Mission éditoriale",
        readMore: "En lis plus",
        readLess: "En lis moins",
        ofSub: "de",
      },
    },
  },
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Grid fullWidth container spacing={1} alignItems={"flex-end"}>
      <Grid item>
        <SearchIcon />
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <FormControl fullWidth>
          <InputLabel htmlFor={"judge-submission-search"}>
            Search {count} submissions...
          </InputLabel>
          <Input
            fullWidth
            id={"judge-submission-search"}
            type={"text"}
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            endAdornment={
              <InputAdornment position={"end"}>
                {value && value.length && (
                  <IconButton
                    aria-label={"clear search"}
                    onClick={() => {
                      setValue("");
                      setGlobalFilter("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

function ScoreCell({ row, updateScores }) {
  const [entry, setEntry] = useState({
    ...row.original,
    scoreError: undefined,
  });

  useEffect(() => {
    let timer;
    if (entry.score) {
      timer = setTimeout(() => {
        updateScores(entry.id, entry.score);
      }, 500);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [entry, updateScores]);

  const updateScore = useCallback(
    (e) => {
      const { value } = e.target;

      // console.log("ScoreCell() updateScore()", { value, entry });

      if (value !== entry.score)
        setEntry((prev) => {
          const score = String(value).trim();

          prev.score = score;
          // console.log("PARSE SCORE", score, typeof score, parseInt(score));
          if (parseInt(score) < 1 || parseInt(score) > 30)
            prev.scoreError = "Please check that your score is between 3-30";
          else prev.scoreError = undefined;
          return { ...prev };
        });
    },
    [entry]
  );

  const entryScoreValue = useCallback(() => {
    if (entry.score) return entry.score < 0 ? "C" : entry.score;
    return undefined;
  }, [entry]);

  return (
    <>
      <TextField
        value={entryScoreValue()}
        id={`score-${entry.id}`}
        variant={"outlined"}
        onChange={updateScore}
        placeholder={"0.00"}
        error={!!entry.scoreError}
        helperText={entry.scoreError}
      />
    </>
  );
}

function ScoreCardTable({ lang, org, submissions, setSubmissions }) {
  console.log("ScoreCardTable()", { submissions });

  const [judgeScores, setJudgeScores] = useState([]);

  useEffect(() => {
    setJudgeScores(submissions);
  }, [submissions]);

  const updateScores = useCallback(
    (id, value) => {
      setSubmissions((prev) =>
        prev.map((judgeEntry) => {
          if (judgeEntry.id === id)
            judgeEntry.score =
              String(value).toLowerCase() === "c" ? -1 : parseFloat(value);
          return judgeEntry;
        })
      );
    },
    [setSubmissions]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: scoreCardTable_text[lang.toLowerCase()].table.header.submission,
        accessor: "submission",
        sortType: (a, b) => (a.original.title > b.original.title ? 1 : -1),
        Cell: ({ row }) => {
          const entry = row.original;
          const title = entry.title;

          return (
            <div className="submission-info">
              <JudgeEntryCard
                title={
                  title ||
                  scoreCardTable_text[lang.toLowerCase()].status.noTitle
                }
                fields={entry.fields}
                defaultExpanded={false}
              />
            </div>
          );
        },
      },
      {
        Header:
          scoreCardTable_text[lang.toLowerCase()].table.header[
            `magazine_${org.toLowerCase()}`
          ],
        accessor: "publication",
        sortType: (a, b) =>
          a.original.publication > b.original.publication ? 1 : -1,
        //TODO: Break each of these Cells into separate files.
        Cell: ({ row }) => {
          const entry = row.original;
          console.log("ENTRY DATA()",entry)
          const publication = entry.publication;
          const fields = entry.fields;

          const mandate = fields.find(
            (field) =>
              field.title.toLowerCase().indexOf("editorial") >= 0 &&
              field.title.toLowerCase().indexOf("mandate") >= 0
          );


          const aiAssistance = fields.find(
            (field) =>
              field.title.toLowerCase().indexOf("ai assistance") >= 0
          );



          const sponsored = fields.find(
            (field) =>
              field.title.toLowerCase().indexOf("sponsored content") >= 0
          );
          
          const contextual = fields.find(
            (field) =>
              field.title.toLowerCase().indexOf("contextual statement") >= 0
          );

          if (!publication) return `(No Publication Info)`;
          else {
            return (
              <div className="publication-info">
                <Typography variant={"body1"} paragraph>
                  {publication}
                </Typography>
                <Typography variant={"body2"} paragraph>
                  {mandate?.value}
                </Typography>


              
                {sponsored && <Typography variant={"body2"} paragraph>
                  <strong>Sponsored Content:</strong> {sponsored?.value}
                </Typography>}


              
                {contextual && <Typography variant={"body2"} paragraph>
                  <strong>Contextual Statement:</strong> {contextual?.value}
                </Typography>}


              
                {aiAssistance && <Typography variant={"body2"} paragraph>
                  <strong>AI Assistance:</strong> {aiAssistance?.value}
                </Typography>}
                
                {/*<Button*/}
                {/*  size={"small"}*/}
                {/*  color={"primary"}*/}
                {/*  href={mandate.value}*/}
                {/*  target={"_blank"}*/}
                {/*  startIcon={<DownloadIcon />}*/}
                {/*  variant={"outlined"}*/}
                {/*>*/}
                {/*  Download Mandate*/}
                {/*</Button>*/}
              </div>
            );
          }
        },
      },
      {
        Header: scoreCardTable_text[lang.toLowerCase()].table.header.score,
        accessor: "score",
        Cell: ({ row }) => {
          return <ScoreCell row={row} updateScores={updateScores} />;
        },
      },
    ],
    [lang, org, updateScores]
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: judgeScores,
      globalFilter: (rows, columnIds, globalFilterValue) => {
        const search = String(globalFilterValue).toLowerCase();
        return matchSorter(rows, search, {
          keys: ["original.title", "original.publication"],
        });
      },
    },

    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {}, [state.globalFilter]);

  return rows.length > 0 ? (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table
        {...getTableProps()}
        className="scorecard-list"
        id="scorecard-list"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div
                      className={column.Header.toLowerCase()}
                      style={{ whitespace: "nowrap" }}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        <span
                          className={` sprite white icon drop-arrow ${
                            column.isSortedDesc ? `` : `go-up`
                          }`}
                        />
                      ) : (
                        ``
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row, i) => {
            return (
              prepareRow(row) || (
                <tr
                  {...row.getRowProps({
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={cell.column.id}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              )
            );
          })}
          <tr className="final-row">
            <td colSpan="5" className="text-center">
              {judgeScores.length} of {judgeScores.length}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <div>
      <Typography variant={"body2"} color={"textSecondary"}>
        Loading table
      </Typography>
    </div>
  );
}

export default ScoreCardTable;

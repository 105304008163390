/* v1: REPLACED BY v2 */
// export const judgeEntries = {
//     "955801e2-961a-4d74-b22d-141ffec5a00f": {
//         id: "955801e2-961a-4d74-b22d-141ffec5a00f",
//         title: "Hakai Magazine",
//         publication: "The publisher",
//         favourite: false,
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: 1,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: 8,
//             },
//         ]
//     },
//     "6ccbc19b-4c3b-4a58-bb98-301a1f1ab67e": {
//         id: "6ccbc19b-4c3b-4a58-bb98-301a1f1ab67e",
//         title: "The Noose Beneath the Waves",
//         publication: "Hakai Magazine",
//         favourite: false,
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "dd4015f9-9aa6-4d31-9c54-6d696d9d032f": {
//         id: "dd4015f9-9aa6-4d31-9c54-6d696d9d032f",
//         title: "Pickup Artists Are Still A Thing. And They Want You To Know They’ve Evolved.",
//         publication: "BuzzFeed Canada",
//         favourite: true,
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "516b737b-f99a-4b11-b74b-63413610e4a3": {
//         id: "516b737b-f99a-4b11-b74b-63413610e4a3",
//         title: "Mourir seul",
//         publication: "L'actualité",
//         favourite: false,
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "40adc534-7292-4ee7-9408-1b739611bf09": {
//         id: "40adc534-7292-4ee7-9408-1b739611bf09",
//         title: "À qui la ville ?",
//         publication: "L'actualité",
//         favourite: false,
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "a5082488-9be2-4e07-977b-2154f4cb823e": {
//         id: "a5082488-9be2-4e07-977b-2154f4cb823e",
//         title: "For the love of pronghorns",
//         publication: "Canadian Geographic",
//         favourite: false,
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "6210da7f-8de8-434a-a6da-a0eef726e308": {
//         id: "6210da7f-8de8-434a-a6da-a0eef726e308",
//         title: "Indigenous guardians of the Nuxalk",
//         publication: "Canadian Geographic",
//         favourite: false,
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "cb87fda3-78e4-4e50-ac72-8e9e1e8e88fe": {
//         id: "cb87fda3-78e4-4e50-ac72-8e9e1e8e88fe",
//         title: "Picked Over",
//         publication: "The Walrus",
//         favourite: false,
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     "fdf0a3cf-a225-4198-8834-c943314bf778": {
//         id: "fdf0a3cf-a225-4198-8834-c943314bf778",
//         title: "Class Divide",
//         publication: "The Walrus",
//         favourite: false,
//         category: {
//             title: "Essays",
//             title_fr: "Essais",
//             description: "Honours outstanding magazine writing in which the writer’s perspective is paramount and offers an argument or an intellectual point of view. The regular columns of columnists are not eligible in Essays.",
//             description_fr: "Cette catégorie cible les articles dans lesquels le point de vue de l’auteur, appuyé par un argument ou un point de vue intellectuel, revêt une importance primordiale. Les chroniques régulières ne sont pas admissibles dans cette catégorie.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }
//         ]
//     },
//     "18d3032e-76b6-4c54-bdc7-e0ae0ce6a596": {
//         id: "18d3032e-76b6-4c54-bdc7-e0ae0ce6a596",
//         title: "Revenir à la maison",
//         publication: "Nouveau Projet",
//         favourite: false,
//         category: {
//             title: "Essays",
//             title_fr: "Essais",
//             description: "Honours outstanding magazine writing in which the writer’s perspective is paramount and offers an argument or an intellectual point of view. The regular columns of columnists are not eligible in Essays.",
//             description_fr: "Cette catégorie cible les articles dans lesquels le point de vue de l’auteur, appuyé par un argument ou un point de vue intellectuel, revêt une importance primordiale. Les chroniques régulières ne sont pas admissibles dans cette catégorie.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }
//         ]
//     }
// }
/* v2: REPLACED BY v3 */
// export const judgeEntries = [
//     {
//         id: "955801e2-961a-4d74-b22d-141ffec5a00f",
//         title: "The Cavernous World under the Woods",
//         creator:'Bruce Grierson',
//         favourite: false,
//         publication: {
//             title: "Hakai Magazine",
//             mandate: 'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//         },
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: 1,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: 8,
//             },
//         ]
//     },
//     {
//         id: "6ccbc19b-4c3b-4a58-bb98-301a1f1ab67e",
//         title: "The Noose Beneath the Waves",
//         favourite: false,
//         creator:'Sasha Chapman',
//         publication: {
//             title: "Hakai Magazine",
//             mandate: 'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//         },
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     {
//         id: "dd4015f9-9aa6-4d31-9c54-6d696d9d032f",
//         title: "Pickup Artists Are Still A Thing. And They Want You To Know They’ve Evolved.",
//         favourite: true,
//         creator:'Scaachi Koul',
//         publication: {
//             title: "BuzzFeed Canada",
//             mandate: 'Started in 2012, BuzzFeed News has grown from a small social news upstart into an international news organization for a generation of news consumers looking for something new. Our mission is to report to you: We cover what you care about, break big stories that hold major institutions accountable for their actions, and expose injustices that change people\'s lives.'
//         },
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     {
//         id: "516b737b-f99a-4b11-b74b-63413610e4a3",
//         title: "Mourir seul",
//         favourite: false,
//         creator:'Noémi Mercier',
//         publication: {
//             title: "L'actualité",
//             mandate: 'L’actualité est un magazine d’affaires publiques voué aux grands reportages, à l’analyse et à la réflexion.'
//         },
//         category: {
//             title: "Long-Form Feature Writing",
//             title_fr: "Long-Form Feature Writing",
//             description: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//             description_fr: "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ]
//     },
//     {
//         id: "40adc534-7292-4ee7-9408-1b739611bf09",
//         title: "À qui la ville ?",
//         favourite: true,
//         creator:'Marc-André Sabourin',
//         publication: {
//             title: "L'actualité",
//             mandate: 'L’actualité est un magazine d’affaires publiques voué aux grands reportages, à l’analyse et à la réflexion.'
//         },
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ]
//     },
//     {
//         id: "a5082488-9be2-4e07-977b-2154f4cb823e",
//         title: "For the love of pronghorns",
//         publication: "Canadian Geographic",
//         favourite: false,
//         creator:'Alanna Mitchell',
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     {
//         id: "6210da7f-8de8-434a-a6da-a0eef726e308",
//         title: "Indigenous guardians of the Nuxalk",
//         favourite: false,
//         creator:'Julian Brave NoiseCat',
//         publication: {
//             title: "Canadian Geographic",
//             mandate: 'To make Canada better known to Canadians and the world.'
//         },
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     {
//         id: "cb87fda3-78e4-4e50-ac72-8e9e1e8e88fe",
//         title: "Picked Over",
//         favourite: false,
//         creator:'Corey Mintz',
//         publication: {
//             title: "The Walrus",
//             mandate: 'The Walrus is a national general interest magazine about Canada and its place in the world. We are committed to presenting the best work by the best writers and artists from Canada and elsewhere, on a wide range of topics for curious readers.'
//         },
//         category: {
//             title: "Feature Writing",
//             title_fr: "Meilleur article de fond",
//             description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
//             description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Accuracy',
//                 title_fr: 'Précision',
//                 priority: 2,
//                 min: 0,
//                 max: 8,
//                 value: null,
//             },
//         ]
//     },
//     {
//         id: "fdf0a3cf-a225-4198-8834-c943314bf778",
//         title: "Class Divide",
//         favourite: false,
//         creator:'Katrina Onstad',
//         publication: {
//             title: "The Walrus",
//             mandate: 'The Walrus is a national general interest magazine about Canada and its place in the world. We are committed to presenting the best work by the best writers and artists from Canada and elsewhere, on a wide range of topics for curious readers.'
//         },
//         category: {
//             title: "Essays",
//             title_fr: "Essais",
//             description: "Honours outstanding magazine writing in which the writer’s perspective is paramount and offers an argument or an intellectual point of view. The regular columns of columnists are not eligible in Essays.",
//             description_fr: "Cette catégorie cible les articles dans lesquels le point de vue de l’auteur, appuyé par un argument ou un point de vue intellectuel, revêt une importance primordiale. Les chroniques régulières ne sont pas admissibles dans cette catégorie.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }
//         ]
//     },
//     {
//         id: "18d3032e-76b6-4c54-bdc7-e0ae0ce6a596",
//         title: "Revenir à la maison",
//         creator:'Nicolas Langelier',
//         favourite: false,
//         publication: {
//             title: "Nouveau Projet",
//             mandate: 'Magazine de référence au Québec, Nouveau Projet parait deux fois par année avec pour mission de publier les meilleurs auteurs et journalistes et de contribuer à l’effervescence de la société québécoise et de la culture francophone en Amérique du Nord.'
//         },
//         category: {
//             title: "Essays",
//             title_fr: "Essais",
//             description: "Honours outstanding magazine writing in which the writer’s perspective is paramount and offers an argument or an intellectual point of view. The regular columns of columnists are not eligible in Essays.",
//             description_fr: "Cette catégorie cible les articles dans lesquels le point de vue de l’auteur, appuyé par un argument ou un point de vue intellectuel, revêt une importance primordiale. Les chroniques régulières ne sont pas admissibles dans cette catégorie.",
//         },
//         media: [
//             {
//                 type:'pdf',
//                 filename:'The Cavernous World under the Woods.pdf',
//                 url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
//             }
//         ],
//         fields:[
//             {
//                 label:'Submission Title',
//                 label_fr:'Titre de l\'article',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'The Cavernous World under the Woods'
//             },
//             {
//                 label:'Date Published',
//                 label_fr:'Publié le',
//                 placeholder:'Month/Year',
//                 placeholder_fr:'Date (mois/année)',
//                 value:'11/2018'
//             },
//             {
//                 label:'Publication Name',
//                 label_fr:'Publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine'
//             },
//             {
//                 label:'Sponsored Content',
//                 label_fr:'Ce contenu a-t-il été commandité?',
//                 placeholder:'Exactly as it should be credited',
//                 placeholder_fr:'',
//                 value:'n/a'
//             },
//             {
//                 label:'Publication Twitter Handle',
//                 label_fr:'Twitter de la publication',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'@hakaimagazine'
//             },
//             {
//                 label:'Creator\'s Name(s)',
//                 label_fr:'Nom de l\'auteur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Bruce Grierson'
//             },
//             {
//                 label:'Handling Editor\'s Name',
//                 label_fr:'Nom du rédacteur-réviseur',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Jude Isabella'
//             },
//
//             {
//                 label:'Other Contributors',
//                 label_fr:'Collaborateurs',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:null
//             },
//
//
//             {
//                 label:'Editorial Mandate',
//                 label_fr:'Mission éditoriale',
//                 placeholder:'Publication',
//                 placeholder_fr:'',
//                 value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
//             }
//         ],
//         score: [
//             {
//                 title: 'Style',
//                 title_fr: 'Style',
//                 priority: 0,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }, {
//                 title: 'Creativity',
//                 title_fr: 'Creativitie',
//                 priority: 1,
//                 min: 0,
//                 max: 10,
//                 value: null,
//             }
//         ]
//     }
// ]

export const judgeEntries = [
  {
    category: {
      zip:
        "https://s3.ca-central-1.amazonaws.com/nma.public/misc/long-form-feature-writing-2018.zip",
      id: "long-form-feature-writing",
      title: "Long-Form Feature Writing",
      title_fr: "Long-Form Feature Writing",
      description:
        "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
      description_fr:
        "Honours outstanding feature writing of a magazine story of minimum 3500 words in length, in any field or subject.",
      score_schema: [
        {
          id: 0,
          title: "Score",
          title_fr: "Score",
          priority: 0,
          min: 1.0,
          max: 30.0,
        },
      ],
    },
    submissions: [
      {
        id: "955801e2-961a-4d74-b22d-141ffec5a00f",
        title: "The Cavernous World under the Woods",
        creator: "Bruce Grierson",
        favourite: false,
        publication: {
          title: "Hakai Magazine",
          mandate:
            "Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.",
        },
        fields: [
          {
            label: "Submission Title",
            label_fr: "Titre de l'article",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "The Cavernous World under the Woods",
          },
          {
            label: "Date Published",
            label_fr: "Publié le",
            placeholder: "Month/Year",
            placeholder_fr: "Date (mois/année)",
            value: "11/2018",
          },
          {
            label: "Publication Name",
            label_fr: "Publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Hakai Magazine",
          },
          {
            label: "Sponsored Content",
            label_fr: "Ce contenu a-t-il été commandité?",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "n/a",
          },
          {
            label: "Publication Twitter Handle",
            label_fr: "Twitter de la publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "@hakaimagazine",
          },
          {
            label: "Creator's Name(s)",
            label_fr: "Nom de l'auteur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Bruce Grierson",
          },
          {
            label: "Handling Editor's Name",
            label_fr: "Nom du rédacteur-réviseur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Jude Isabella",
          },

          {
            label: "Other Contributors",
            label_fr: "Collaborateurs",
            placeholder: "Publication",
            placeholder_fr: "",
            value: null,
          },

          {
            label: "Editorial Mandate",
            label_fr: "Mission éditoriale",
            placeholder: "Publication",
            placeholder_fr: "",
            value:
              "Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.",
          },
        ],
        media: [
          {
            type: "pdf",
            filename: "The Cavernous World under the Woods.pdf",
            url:
              "https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf",
          },
        ],
        score: [
          {
            id: 0,
            score_ref: 0,
            title: "Score",
            title_fr: "Score",
            value: 1,
          },
        ],
      },
      {
        id: "6ccbc19b-4c3b-4a58-bb98-301a1f1ab67e",
        title: "The Noose Beneath the Waves",
        favourite: false,
        creator: "Sasha Chapman",
        publication: {
          title: "Hakai Magazine",
          mandate:
            "Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.",
        },
        media: [
          {
            type: "pdf",
            filename: "The Cavernous World under the Woods.pdf",
            url:
              "https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0aa3c838-14e6-4e3d-9b5e-a2b203c17908.pdf",
          },
        ],
        fields: [
          {
            label: "Submission Title",
            label_fr: "Titre de l'article",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "The Cavernous World under the Woods",
          },
          {
            label: "Date Published",
            label_fr: "Publié le",
            placeholder: "Month/Year",
            placeholder_fr: "Date (mois/année)",
            value: "11/2018",
          },
          {
            label: "Publication Name",
            label_fr: "Publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Hakai Magazine",
          },
          {
            label: "Sponsored Content",
            label_fr: "Ce contenu a-t-il été commandité?",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "n/a",
          },
          {
            label: "Publication Twitter Handle",
            label_fr: "Twitter de la publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "@hakaimagazine",
          },
          {
            label: "Creator's Name(s)",
            label_fr: "Nom de l'auteur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Bruce Grierson",
          },
          {
            label: "Handling Editor's Name",
            label_fr: "Nom du rédacteur-réviseur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Jude Isabella",
          },

          {
            label: "Other Contributors",
            label_fr: "Collaborateurs",
            placeholder: "Publication",
            placeholder_fr: "",
            value: null,
          },

          {
            label: "Editorial Mandate",
            label_fr: "Mission éditoriale",
            placeholder: "Publication",
            placeholder_fr: "",
            value:
              "Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.",
          },
        ],
        score: [
          {
            id: 3,
            title: "Score",
            title_fr: "Score",
            score_ref: 0,
            value: null,
          },
        ],
      },
      {
        id: "dd4015f9-9aa6-4d31-9c54-6d696d9d032f",
        title:
          "Pickup Artists Are Still A Thing. And They Want You To Know They’ve Evolved.",
        favourite: true,
        creator: "Scaachi Koul",
        publication: {
          title: "BuzzFeed Canada",
          mandate:
            "Started in 2012, BuzzFeed News has grown from a small social news upstart into an international news organization for a generation of news consumers looking for something new. Our mission is to report to you: We cover what you care about, break big stories that hold major institutions accountable for their actions, and expose injustices that change people's lives.",
        },
        media: [
          {
            type: "pdf",
            filename: "The Cavernous World under the Woods.pdf",
            url:
              "https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/7455d78c-517e-45ff-bed3-5896c94f8000.pdf",
          },
        ],
        fields: [
          {
            label: "Submission Title",
            label_fr: "Titre de l'article",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "The Cavernous World under the Woods",
          },
          {
            label: "Date Published",
            label_fr: "Publié le",
            placeholder: "Month/Year",
            placeholder_fr: "Date (mois/année)",
            value: "11/2018",
          },
          {
            label: "Publication Name",
            label_fr: "Publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Hakai Magazine",
          },
          {
            label: "Sponsored Content",
            label_fr: "Ce contenu a-t-il été commandité?",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "n/a",
          },
          {
            label: "Publication Twitter Handle",
            label_fr: "Twitter de la publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "@hakaimagazine",
          },
          {
            label: "Creator's Name(s)",
            label_fr: "Nom de l'auteur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Bruce Grierson",
          },
          {
            label: "Handling Editor's Name",
            label_fr: "Nom du rédacteur-réviseur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Jude Isabella",
          },

          {
            label: "Other Contributors",
            label_fr: "Collaborateurs",
            placeholder: "Publication",
            placeholder_fr: "",
            value: null,
          },

          {
            label: "Editorial Mandate",
            label_fr: "Mission éditoriale",
            placeholder: "Publication",
            placeholder_fr: "",
            value:
              "Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.",
          },
        ],
        score: [
          {
            id: 4,
            title: "Score",
            title_fr: "Score",
            score_ref: 0,
            value: 15.5,
          },
        ],
      },
      {
        id: "516b737b-f99a-4b11-b74b-63413610e4a3",
        title: "Mourir seul",
        favourite: false,
        creator: "Noémi Mercier",
        publication: {
          title: "L'actualité",
          mandate:
            "L’actualité est un magazine d’affaires publiques voué aux grands reportages, à l’analyse et à la réflexion.",
        },
        media: [
          {
            type: "pdf",
            filename: "The Cavernous World under the Woods.pdf",
            url:
              "https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/35e0e4dd-2013-4ccb-9330-124a861c8bca.pdf",
          },
        ],
        score: [
          {
            id: 5,
            title: "Score",
            title_fr: "Score",
            score_ref: 0,
            value: null,
          },
        ],
        fields: [
          {
            label: "Submission Title",
            label_fr: "Titre de l'article",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "The Cavernous World under the Woods",
          },
          {
            label: "Date Published",
            label_fr: "Publié le",
            placeholder: "Month/Year",
            placeholder_fr: "Date (mois/année)",
            value: "11/2018",
          },
          {
            label: "Publication Name",
            label_fr: "Publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Hakai Magazine",
          },
          {
            label: "Sponsored Content",
            label_fr: "Ce contenu a-t-il été commandité?",
            placeholder: "Exactly as it should be credited",
            placeholder_fr: "",
            value: "n/a",
          },
          {
            label: "Publication Twitter Handle",
            label_fr: "Twitter de la publication",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "@hakaimagazine",
          },
          {
            label: "Creator's Name(s)",
            label_fr: "Nom de l'auteur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Bruce Grierson",
          },
          {
            label: "Handling Editor's Name",
            label_fr: "Nom du rédacteur-réviseur",
            placeholder: "Publication",
            placeholder_fr: "",
            value: "Jude Isabella",
          },

          {
            label: "Other Contributors",
            label_fr: "Collaborateurs",
            placeholder: "Publication",
            placeholder_fr: "",
            value: null,
          },

          {
            label: "Editorial Mandate",
            label_fr: "Mission éditoriale",
            placeholder: "Publication",
            placeholder_fr: "",
            value:
              "Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.",
          },
        ],
      },
    ],
  },
  // {
  //     category: {
  //         zip:'https://s3.ca-central-1.amazonaws.com/nma.public/misc/long-form-feature-writing-2018.zip',
  //         id:'feature-writing',
  //         title: "Feature Writing",
  //         title_fr: "Meilleur article de fond",
  //         description: "Honours outstanding feature writing of a magazine story between 2000-3500 words in length, in any field or subject.",
  //         description_fr: "Ce prix vise à honorer l’auteur de l’article de fond (2 000 à 3 500 mots) jugé le mieux rédigé. Tous les sujets sont admissibles.",
  //     },
  //     submissions:[
  //         {
  //             id: "40adc534-7292-4ee7-9408-1b739611bf09",
  //             title: "À qui la ville ?",
  //             favourite: true,
  //             creator:'Marc-André Sabourin',
  //             publication: {
  //                 title: "L'actualité",
  //                 mandate: 'L’actualité est un magazine d’affaires publiques voué aux grands reportages, à l’analyse et à la réflexion.'
  //             },
  //             media: [
  //                 {
  //                     type:'pdf',
  //                     filename:'The Cavernous World under the Woods.pdf',
  //                     url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
  //                 }
  //             ],
  //             score: [
  //                 {
  //                     id:6,
  //                     title: 'Style',
  //                     title_fr: 'Style',
  //                     priority: 0,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:7,
  //                     title: 'Creativity',
  //                     title_fr: 'Creativitie',
  //                     priority: 1,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:8,
  //                     title: 'Accuracy',
  //                     title_fr: 'Précision',
  //                     priority: 2,
  //                     min: 0,
  //                     max: 8,
  //                     value: null,
  //                 },
  //             ],
  //             fields:[
  //                 {
  //                     label:'Submission Title',
  //                     label_fr:'Titre de l\'article',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'The Cavernous World under the Woods'
  //                 },
  //                 {
  //                     label:'Date Published',
  //                     label_fr:'Publié le',
  //                     placeholder:'Month/Year',
  //                     placeholder_fr:'Date (mois/année)',
  //                     value:'11/2018'
  //                 },
  //                 {
  //                     label:'Publication Name',
  //                     label_fr:'Publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine'
  //                 },
  //                 {
  //                     label:'Sponsored Content',
  //                     label_fr:'Ce contenu a-t-il été commandité?',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'n/a'
  //                 },
  //                 {
  //                     label:'Publication Twitter Handle',
  //                     label_fr:'Twitter de la publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'@hakaimagazine'
  //                 },
  //                 {
  //                     label:'Creator\'s Name(s)',
  //                     label_fr:'Nom de l\'auteur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Bruce Grierson'
  //                 },
  //                 {
  //                     label:'Handling Editor\'s Name',
  //                     label_fr:'Nom du rédacteur-réviseur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Jude Isabella'
  //                 },
  //
  //                 {
  //                     label:'Other Contributors',
  //                     label_fr:'Collaborateurs',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:null
  //                 },
  //
  //
  //                 {
  //                     label:'Editorial Mandate',
  //                     label_fr:'Mission éditoriale',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
  //                 }
  //             ]
  //         },
  //         {
  //             id: "a5082488-9be2-4e07-977b-2154f4cb823e",
  //             title: "For the love of pronghorns",
  //             publication: "Canadian Geographic",
  //             favourite: false,
  //             creator:'Alanna Mitchell',
  //             media: [
  //                 {
  //                     type:'pdf',
  //                     filename:'The Cavernous World under the Woods.pdf',
  //                     url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
  //                 }
  //             ],
  //             fields:[
  //                 {
  //                     label:'Submission Title',
  //                     label_fr:'Titre de l\'article',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'The Cavernous World under the Woods'
  //                 },
  //                 {
  //                     label:'Date Published',
  //                     label_fr:'Publié le',
  //                     placeholder:'Month/Year',
  //                     placeholder_fr:'Date (mois/année)',
  //                     value:'11/2018'
  //                 },
  //                 {
  //                     label:'Publication Name',
  //                     label_fr:'Publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine'
  //                 },
  //                 {
  //                     label:'Sponsored Content',
  //                     label_fr:'Ce contenu a-t-il été commandité?',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'n/a'
  //                 },
  //                 {
  //                     label:'Publication Twitter Handle',
  //                     label_fr:'Twitter de la publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'@hakaimagazine'
  //                 },
  //                 {
  //                     label:'Creator\'s Name(s)',
  //                     label_fr:'Nom de l\'auteur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Bruce Grierson'
  //                 },
  //                 {
  //                     label:'Handling Editor\'s Name',
  //                     label_fr:'Nom du rédacteur-réviseur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Jude Isabella'
  //                 },
  //
  //                 {
  //                     label:'Other Contributors',
  //                     label_fr:'Collaborateurs',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:null
  //                 },
  //
  //
  //                 {
  //                     label:'Editorial Mandate',
  //                     label_fr:'Mission éditoriale',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
  //                 }
  //             ],
  //             score: [
  //                 {
  //                     id:8,
  //                     title: 'Style',
  //                     title_fr: 'Style',
  //                     priority: 0,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:9,
  //                     title: 'Creativity',
  //                     title_fr: 'Creativitie',
  //                     priority: 1,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:10,
  //                     title: 'Accuracy FOUND ME',
  //                     title_fr: 'Précision',
  //                     priority: 2,
  //                     min: 0,
  //                     max: 8,
  //                     value: null,
  //                 },
  //             ]
  //         },
  //         {
  //             id: "6210da7f-8de8-434a-a6da-a0eef726e308",
  //             title: "Indigenous guardians of the Nuxalk",
  //             favourite: false,
  //             creator:'Julian Brave NoiseCat',
  //             publication: {
  //                 title: "Canadian Geographic",
  //                 mandate: 'To make Canada better known to Canadians and the world.'
  //             },
  //             media: [
  //                 {
  //                     type:'pdf',
  //                     filename:'The Cavernous World under the Woods.pdf',
  //                     url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
  //                 }
  //             ],
  //             fields:[
  //                 {
  //                     label:'Submission Title',
  //                     label_fr:'Titre de l\'article',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'The Cavernous World under the Woods'
  //                 },
  //                 {
  //                     label:'Date Published',
  //                     label_fr:'Publié le',
  //                     placeholder:'Month/Year',
  //                     placeholder_fr:'Date (mois/année)',
  //                     value:'11/2018'
  //                 },
  //                 {
  //                     label:'Publication Name',
  //                     label_fr:'Publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine'
  //                 },
  //                 {
  //                     label:'Sponsored Content',
  //                     label_fr:'Ce contenu a-t-il été commandité?',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'n/a'
  //                 },
  //                 {
  //                     label:'Publication Twitter Handle',
  //                     label_fr:'Twitter de la publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'@hakaimagazine'
  //                 },
  //                 {
  //                     label:'Creator\'s Name(s)',
  //                     label_fr:'Nom de l\'auteur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Bruce Grierson'
  //                 },
  //                 {
  //                     label:'Handling Editor\'s Name',
  //                     label_fr:'Nom du rédacteur-réviseur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Jude Isabella'
  //                 },
  //
  //                 {
  //                     label:'Other Contributors',
  //                     label_fr:'Collaborateurs',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:null
  //                 },
  //
  //
  //                 {
  //                     label:'Editorial Mandate',
  //                     label_fr:'Mission éditoriale',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
  //                 }
  //             ],
  //             score: [
  //                 {
  //                     id:11,
  //                     title: 'Style',
  //                     title_fr: 'Style',
  //                     priority: 0,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:12,
  //                     title: 'Creativity',
  //                     title_fr: 'Creativitie',
  //                     priority: 1,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:13,
  //                     title: 'Accuracy',
  //                     title_fr: 'Précision',
  //                     priority: 2,
  //                     min: 0,
  //                     max: 8,
  //                     value: null,
  //                 },
  //             ]
  //         },
  //         {
  //             id: "cb87fda3-78e4-4e50-ac72-8e9e1e8e88fe",
  //             title: "Picked Over",
  //             favourite: false,
  //             creator:'Corey Mintz',
  //             publication: {
  //                 title: "The Walrus",
  //                 mandate: 'The Walrus is a national general interest magazine about Canada and its place in the world. We are committed to presenting the best work by the best writers and artists from Canada and elsewhere, on a wide range of topics for curious readers.'
  //             },
  //             media: [
  //                 {
  //                     type:'pdf',
  //                     filename:'The Cavernous World under the Woods.pdf',
  //                     url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
  //                 }
  //             ],
  //             fields:[
  //                 {
  //                     label:'Submission Title',
  //                     label_fr:'Titre de l\'article',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'The Cavernous World under the Woods'
  //                 },
  //                 {
  //                     label:'Date Published',
  //                     label_fr:'Publié le',
  //                     placeholder:'Month/Year',
  //                     placeholder_fr:'Date (mois/année)',
  //                     value:'11/2018'
  //                 },
  //                 {
  //                     label:'Publication Name',
  //                     label_fr:'Publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine'
  //                 },
  //                 {
  //                     label:'Sponsored Content',
  //                     label_fr:'Ce contenu a-t-il été commandité?',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'n/a'
  //                 },
  //                 {
  //                     label:'Publication Twitter Handle',
  //                     label_fr:'Twitter de la publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'@hakaimagazine'
  //                 },
  //                 {
  //                     label:'Creator\'s Name(s)',
  //                     label_fr:'Nom de l\'auteur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Bruce Grierson'
  //                 },
  //                 {
  //                     label:'Handling Editor\'s Name',
  //                     label_fr:'Nom du rédacteur-réviseur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Jude Isabella'
  //                 },
  //
  //                 {
  //                     label:'Other Contributors',
  //                     label_fr:'Collaborateurs',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:null
  //                 },
  //
  //
  //                 {
  //                     label:'Editorial Mandate',
  //                     label_fr:'Mission éditoriale',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
  //                 }
  //             ],
  //             score: [
  //                 {
  //                     id:14,
  //                     title: 'Style',
  //                     title_fr: 'Style',
  //                     priority: 0,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:15,
  //                     title: 'Creativity',
  //                     title_fr: 'Creativitie',
  //                     priority: 1,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:16,
  //                     title: 'Accuracy',
  //                     title_fr: 'Précision',
  //                     priority: 2,
  //                     min: 0,
  //                     max: 8,
  //                     value: null,
  //                 },
  //             ]
  //         },
  //     ]
  // },
  // {
  //     category: {
  //         zip:'https://s3.ca-central-1.amazonaws.com/nma.public/misc/long-form-feature-writing-2018.zip',
  //         id:'essays',
  //         title: "Essays",
  //         title_fr: "Essais",
  //         description: "Honours outstanding magazine writing in which the writer’s perspective is paramount and offers an argument or an intellectual point of view. The regular columns of columnists are not eligible in Essays.",
  //         description_fr: "Cette catégorie cible les articles dans lesquels le point de vue de l’auteur, appuyé par un argument ou un point de vue intellectuel, revêt une importance primordiale. Les chroniques régulières ne sont pas admissibles dans cette catégorie.",
  //     },
  //     submissions:[
  //         {
  //             id: "fdf0a3cf-a225-4198-8834-c943314bf778",
  //             title: "Class Divide",
  //             favourite: false,
  //             creator:'Katrina Onstad',
  //             publication: {
  //                 title: "The Walrus",
  //                 mandate: 'The Walrus is a national general interest magazine about Canada and its place in the world. We are committed to presenting the best work by the best writers and artists from Canada and elsewhere, on a wide range of topics for curious readers.'
  //             },
  //
  //             media: [
  //                 {
  //                     type:'pdf',
  //                     filename:'The Cavernous World under the Woods.pdf',
  //                     url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
  //                 }
  //             ],
  //             fields:[
  //                 {
  //                     label:'Submission Title',
  //                     label_fr:'Titre de l\'article',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'The Cavernous World under the Woods'
  //                 },
  //                 {
  //                     label:'Date Published',
  //                     label_fr:'Publié le',
  //                     placeholder:'Month/Year',
  //                     placeholder_fr:'Date (mois/année)',
  //                     value:'11/2018'
  //                 },
  //                 {
  //                     label:'Publication Name',
  //                     label_fr:'Publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine'
  //                 },
  //                 {
  //                     label:'Sponsored Content',
  //                     label_fr:'Ce contenu a-t-il été commandité?',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'n/a'
  //                 },
  //                 {
  //                     label:'Publication Twitter Handle',
  //                     label_fr:'Twitter de la publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'@hakaimagazine'
  //                 },
  //                 {
  //                     label:'Creator\'s Name(s)',
  //                     label_fr:'Nom de l\'auteur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Bruce Grierson'
  //                 },
  //                 {
  //                     label:'Handling Editor\'s Name',
  //                     label_fr:'Nom du rédacteur-réviseur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Jude Isabella'
  //                 },
  //
  //                 {
  //                     label:'Other Contributors',
  //                     label_fr:'Collaborateurs',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:null
  //                 },
  //
  //
  //                 {
  //                     label:'Editorial Mandate',
  //                     label_fr:'Mission éditoriale',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
  //                 }
  //             ],
  //             score: [
  //                 {
  //                     id:17,
  //                     title: 'Style',
  //                     title_fr: 'Style',
  //                     priority: 0,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:18,
  //                     title: 'Creativity',
  //                     title_fr: 'Creativitie',
  //                     priority: 1,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }
  //             ]
  //         },
  //         {
  //             id: "18d3032e-76b6-4c54-bdc7-e0ae0ce6a596",
  //             title: "Revenir à la maison",
  //             creator:'Nicolas Langelier',
  //             favourite: false,
  //             publication: {
  //                 title: "Nouveau Projet",
  //                 mandate: 'Magazine de référence au Québec, Nouveau Projet parait deux fois par année avec pour mission de publier les meilleurs auteurs et journalistes et de contribuer à l’effervescence de la société québécoise et de la culture francophone en Amérique du Nord.'
  //             },
  //             media: [
  //                 {
  //                     type:'pdf',
  //                     filename:'The Cavernous World under the Woods.pdf',
  //                     url:'https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/0ea8da4a-cfff-4dde-aae5-32e8b5498073.pdf'
  //                 }
  //             ],
  //             fields:[
  //                 {
  //                     label:'Submission Title',
  //                     label_fr:'Titre de l\'article',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'The Cavernous World under the Woods'
  //                 },
  //                 {
  //                     label:'Date Published',
  //                     label_fr:'Publié le',
  //                     placeholder:'Month/Year',
  //                     placeholder_fr:'Date (mois/année)',
  //                     value:'11/2018'
  //                 },
  //                 {
  //                     label:'Publication Name',
  //                     label_fr:'Publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine'
  //                 },
  //                 {
  //                     label:'Sponsored Content',
  //                     label_fr:'Ce contenu a-t-il été commandité?',
  //                     placeholder:'Exactly as it should be credited',
  //                     placeholder_fr:'',
  //                     value:'n/a'
  //                 },
  //                 {
  //                     label:'Publication Twitter Handle',
  //                     label_fr:'Twitter de la publication',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'@hakaimagazine'
  //                 },
  //                 {
  //                     label:'Creator\'s Name(s)',
  //                     label_fr:'Nom de l\'auteur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Bruce Grierson'
  //                 },
  //                 {
  //                     label:'Handling Editor\'s Name',
  //                     label_fr:'Nom du rédacteur-réviseur',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Jude Isabella'
  //                 },
  //
  //                 {
  //                     label:'Other Contributors',
  //                     label_fr:'Collaborateurs',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:null
  //                 },
  //
  //
  //                 {
  //                     label:'Editorial Mandate',
  //                     label_fr:'Mission éditoriale',
  //                     placeholder:'Publication',
  //                     placeholder_fr:'',
  //                     value:'Hakai Magazine’s editorial mandate is to explore science, society, and the environment through compelling journalism that highlights coastal life and phenomena. Our writers examine coastal matters from multiple perspectives and translate complex ideas into authoritative, engaging stories. Our stories are about the coast—past, present, and future.'
  //                 }
  //             ],
  //             score: [
  //                 {
  //                     id:19,
  //                     title: 'Style',
  //                     title_fr: 'Style',
  //                     priority: 0,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }, {
  //                     id:20,
  //                     title: 'Creativity',
  //                     title_fr: 'Creativitie',
  //                     priority: 1,
  //                     min: 0,
  //                     max: 10,
  //                     value: null,
  //                 }
  //             ]
  //         }
  //     ]
  // }
];

export default judgeEntries;

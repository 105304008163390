import axios from "axios";
import constants from "../utils/constants";
import authService from "../api/auth";
import refresh from "../utils/refresh";

const getBaseUrl = (hostname) => {
  try {
    if (constants.app.env === "development") return constants.api.url;
    const host = hostname.split(".");
    host.shift();
    return "https://api." + host.join(".");
  } catch (e) {
    return constants.api.url;
  }
};

const getClient = () => {
  return axios.create({
    // baseURL: constants.app.env === 'development' ? constants.api.url : ,
    baseURL: getBaseUrl(window.location.hostname),
    headers: {
      Accept: "application/json, application/zip",
      "Content-Type": "application/json",
      "X-App": constants.app.code,
    },
    withCredentials: true,
  });
};

const client = getClient();

refresh(
  client,
  () => {
    // alert("Refresh error");
    // console.log("Refresh error", { authService });
    authService.logout();
  },
  {
    pauseInstanceWhileRefreshing: true,
  }
);

export { client };

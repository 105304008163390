import React, { Suspense, useState, useEffect } from "react";
import Layout from "./Components/Common/Layout";
import { Router, View } from "react-navi";
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { authService } from "./api/auth";
import routes from "./utils/routes";

const theme = createMuiTheme();

function App() {
  const getOrganizationFromPathname = (url) => {
    try {
      switch (url.toLowerCase()) {
        case "digitalpublishingawards.ca":
        case "judging.digitalpublishingawards.ca":
        case "?digitalpublishingawards":
          return "DPA";
        case "nmab2b.com":
        case "judging.nmab2b.com":
        case "?nmab2b":
          return "B2B";
        case "www.magazine-awards.com":
        case "judging.magazine-awards.com":
        case "?magazine-awards.com":
        default:
          return "NMA";
      }
    } catch (e) {
      return "NMA";
    }
  };

  const org = getOrganizationFromPathname(window.location.hostname);
  const [lang, setLang] = useState("en");
  const [year] = useState(2023); //TODO: Pull the year

  const [user, setUser] = useState(authService.getUser());
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const { t, i18n } = useTranslation()

  useEffect(() => {
    authService.subscribeUser(setUser);
  }, []);

  useEffect(() => {
    authService
      .isAuthenticated()
      .then((isAuthResponse) => {
        setIsAuthenticated(isAuthResponse);
        console.log('User',user)
        if(!isAuthResponse && user?.token) authService.logout()
      })
      .catch((err) => {
        console.log("Error Checking Authentication", err.message);
      });
  }, [user]);


  useEffect(() => {
    // console.log("lang");
  }, [lang]);

  const changePageLanguage = (lang_code) => {
    if (lang !== lang_code) {
      setLang(lang_code);
    }
  };

  console.log('App()',user)
  return (
    <Suspense fallback={<Loader />}>
      <Router
        routes={routes}
        context={{
          ...user,
          user,
          year,
          org,
          lang,
          setLang,
          authService,
        }}
      >
        <Layout
          {...{ authService, isAuthenticated, lang, org }}
          setLang={changePageLanguage}
        >
          <View />
        </Layout>
      </Router>
    </Suspense>
  );
}

const Loader = () => (
  <div className="App">
    <div>Loading...</div>
  </div>
);

export default App;

import React, { useState } from "react";
import { useNavigation } from "react-navi";
import { LabelImportant } from "@material-ui/icons";
import InputGroup from "../Judge/InputGroup";
import { Card, CardContent } from "@material-ui/core";

const login_text = {
  en: {
    intro: {
      heading: "Welcome, Judge",
      intro: `To access the National Magazine Awards Judging platform, please enter your email and password.`,
      important: (
        <>
          <LabelImportant /> Your password has been changed. Please log back in
          using your new password.
        </>
      ),
      error: (
        <>
          <LabelImportant /> Your password has been changed. Please log back in
          using your new password.
        </>
      ),
      passwordPrompt: (
        <p>
          <strong>Where's my password?</strong>
          <br />
          Your password was emailed to you with an option to reset it. Please{" "}
          <a href="mailto:staff@magazine-awards.com">contact us</a> if you
          experience any difficulties.
        </p>
      ),
    },
    new_reg: {
      heading: "<strong>Thank you for registering.</strong>",
      body:
        "You have successfully registered an account. Please sign in below to continue.",
    },
    form: {
      validation_error: "Please enter a value",
      required_error: "This is required field.",
      login_error:
        "We could not log you in. Please check your username and password.",
      username: {
        label: "Username (Email)",
        placeholder: "Username...",
        error: "Please enter your email address",
      },
      password: {
        label: "Password",
        placeholder: "••••••",
        error: "",
      },
      register_btn: "Register Your Account",
      login_btn: "Login",
      password_reset: {
        heading: "Forgot Your Password?",
        cta: "Please click here to reset your password",
      },
    },
  },
  fr: {
    intro: {
      heading: "Bienvenue,",
      intro: `Sur le portail d\'évaluation`,
      important: `Important ! Vous devez créer un nouveau compte même si vous avez participé lors d'une édition précédente.  Le processus est très simple et ne prend qu'une minute.`,
      annual_account_message: `Si vous avez déjà créé votre compte cette année, veuillez saisir votre courriel et votre mot de passe.`,
    },
    new_reg: {
      heading: <strong>Merci!</strong>,
      body:
        "Votre compte a été créé avec succès. Veuillez saisir votre identifiant et votre mot de passe pour vous connecter.",
    },
    form: {
      validation_error: "Please enter a value",
      required_error: "Ce champ est requis.",
      login_error:
        "Connexion impossible. Veuillez vérifier l'identifiant et le mot de passe.",
      username: {
        label: "Identifiant (Courriel)",
        placeholder: "Courriel ...",
        error: "Veuillez saisir votre adresse courriel",
      },
      password: {
        label: "Mot de passe",
        placeholder: "••••••",
        error: "Votre mot de passe doit contenir au moins 6 caractères",
      },
      register_btn: "Créer un compte",
      login_btn: "Se connecter",
      password_reset: {
        heading: "Mot de passe oublié?",
        cta: "Cliquez ici pour le réinitialiser ",
      },
    },
  },
};

function Login({ authService, lang, org }) {
  const [formError, setFormError] = useState("");
  const [login, setLogin] = useState({
    username: {
      value: "",
      error: "",
      id: "login_username",
      validate: {
        required: true,
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        error: "Please enter your email address",
      },
    },
    password: {
      value: "",
      error: "",
      id: "login_password",
      validate: {
        required: true,
        pattern: /^.{6,}$/,
        error: "Passwords are minimum 6 characters long.",
      },
    },
  });

  let navigation = useNavigation();

  let handleSubmit = async (e) => {
    e.preventDefault();
    // try {
      const checkLogin = await authService.login({
        username: login.username.value,
        password: login.password.value,
      });
      if(checkLogin && checkLogin.token && checkLogin.token.length > 0){
        if (formError.length) setFormError("");
        if(checkLogin.forcePasswordReset) await navigation.navigate(`/changePassword/?resetToken=${checkLogin.resetToken}`);
        else await navigation.navigate("/judge/dashboard/");
      } else {
        setFormError(login_text[lang.toLowerCase()].form.login_error);
      }

    // } catch (e) {
    //   console.log("Could not Submit");
    //   console.log(e);
    // }
  };

  const handleChange = (e) => {
    if (typeof e.target.value !== "undefined") {
      // setLogin({...login, [e.target.id]:e.target.value})
      doValidation(e.target.name, e.target.value);
    }
  };

  const doValidation = (name, value) => {
    let error = "";
    const validate = login[name].validate;
    if (validate !== null && typeof validate === "object") {
      if (validate.required) {
        if (!value) {
          error = "This is required field.";
        }
      }

      if (value && !validate.pattern.test(value)) {
        error = validate.error;
      }
    }

    setLogin((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], value, error },
    }));
  };

  // console.log('authService.user')
  // console.log(authService.user)

  return (
    <form>
      <h1>{login_text[lang].intro.heading}</h1>
      {/*<Card style={{ background: "#f6f6f6" }}>*/}
      {/*  <CardContent>*/}
      {/*    Sunday, Feb 28, 2021 @8:15AM. We are currently undergoing some*/}
      {/*    maintenance -- you may experience some service interruption.*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}

      {!!authService.user && authService.user.newPassword && (
        <div
          style={{
            background: "#e0e0e0",
            border: "1px solid #aaa",
            borderRadius: "5px",
            padding: "12px 20px",
            margin: "15px 0",
            fontSize: "1.1em",
          }}
        >
          {login_text[lang].intro.important}
        </div>
      )}

      <p>{login_text[lang].intro.intro}</p>

      <InputGroup
        id="login_username"
        onInputChange={handleChange}
        value={login.username.value}
        type="text"
        label={login_text[lang].form.username.label}
        name="username"
        error={login.username.error}
        placeholder="Username..."
      />
      <InputGroup
        id="login_password"
        onInputChange={handleChange}
        value={login.password.value}
        type="password"
        label={login_text[lang].form.password.label}
        name="password"
        error={login.password.error}
        placeholder="••••••"
      />
      {formError.length > 0 && (
        <div
          style={{
            background: "#ffa4a2",
            border: "1px solid #af4448",
            borderRadius: "5px",
            padding: "12px 20px",
            margin: "15px 0",
            fontSize: "1.1em",
          }}
        >
          {formError}
        </div>
      )}

      <div className="text-right btn-wrap">
        <button
          id="submit"
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          {login_text[lang].form.login_btn}
        </button>
      </div>
      {login_text[lang].intro.passwordPrompt}
      <a href={'/resetPassword'}>Reset your password</a>
    </form>
  );
}

export default Login;

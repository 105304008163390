import { client } from "./client";

export const authService = {
  getUser() {
    return JSON.parse(window.localStorage.getItem("auth"));
  },
  async login(data) {
    const loginState = await this.checkLogin(data);
    const judgeData = {
      token: "",
      expiry: "",
      resetToken: "",
      forcePasswordReset: false,
    };
    if (loginState) {
      const { resetToken, forceReset } = await this.checkForForceReset();

      judgeData.token = loginState.token;
      judgeData.expiry = loginState.expiry;
      judgeData.forcePasswordReset = forceReset;
      judgeData.resetToken = resetToken;

      await this.setAuth(judgeData);
    }
    console.log("Returning Judge Data", judgeData);
    return judgeData;
  },
  async checkLogin({ username, password }) {
    try {
      const req = await client.post("/auth/login/judge", {
        username,
        password,
      });
      if (req.status === 200) return req.data;
      return false;
    } catch (e) {
      // console.log("Could not auth", e.message);
      return false;
    }
  },
  async logout(callback = () => {}) {
    console.log("JUDGE LOGOUT", { client });
    client
      .get("/auth/logout/")
      .then((res) => {
        this.removeUserFromLocalStore();
        console.log("LOGOUT SUCCESS", res);
        callback();
      })
      .catch((err) => {
        console.log("LOGOUT ERROR", err);
      });
  },
  async refresh() {
    try {
      const refresh = await client.get("/auth/refresh");
      // console.log("REFRESH", refresh);
      if (refresh.status === 200) {
        return await this.setAuth({
          token: refresh.token,
          expiry: refresh.expiry,
        });
      }
    } catch (e) {
      console.log("REFRESH ERROR");
      this.logout();
      // throw new Error("Could not refresh");
    }
  },
  async checkForForceReset() {
    const req = await client.get("/auth/password/checkForReset");
    if (req.status === 200)
      return (
        req &&
        req.data && {
          forceReset: req.data.forceReset,
          resetToken: req.data.resetToken,
        }
      );
    else return false;
  },

  async registerNewSubmitter(data) {
    try {
      const req = await client.post(`/auth/register/judge?org=${data.org}`, {
        data,
      });
      if (req.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    }
  },
  async isAuthenticated() {
    const req = await client.get("/auth/check");
    if (req.status === 200) return req.data.check;
    return false;
  },
  subscribeUser(callback) {
    this.setUser = callback;
    return () => {
      this.setUser = undefined;
    };
  },
  saveUserToLocalStore(user) {
    if (user) {
      localStorage.setItem("auth", JSON.stringify(user));
    }
  },
  removeUserFromLocalStore() {
    window.localStorage.removeItem("auth");
  },
  async setAuth({ token, expiry, forcePasswordReset, resetToken }) {
    this.user = { token, expiry, forcePasswordReset, resetToken };
    this.saveUserToLocalStore(this.user);
    if (this.setUser) {
      this.setUser(this.user);
    }
  },

  async resetPassword({ email, org, lang }) {
    console.log("Auth resetPassword()", { email, org, lang });
    try {
      const reset = await client.post("/auth/password/reset", {
        email,
        org,
        lang,
        type: "judge",
      });
      console.log("RESET RESULT()", reset);
      return !!(reset && reset.data.success);
    } catch (e) {
      console.log("Auth ChangePassword() Failure", e.message);
      return false;
    }
  },

  async changePassword({ token, password }) {
    try {
      const change = await client.post("/auth/password/change", {
        token,
        password,
      });
      console.log("CHANGE RESULT()", change);
      return !!(change && change.data.success);
    } catch (e) {
      console.log("Auth ChangePassword() Failure", e.message);
      return false;
    }
  },
};

export default authService;

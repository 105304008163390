import { Typography } from "@material-ui/core";
import React from "react";
import { useNavigation } from "react-navi";

function CategoryTable({ categories, lang, org }) {
  // console.log("CategoryTable");
  // console.log(categories);

  let navigation = useNavigation();

  // const data = score.processJudgeDataForSummary(judgeEntries, max)

  return (
    <ul className="category-list">
      {categories.sort((a,b)=>a.id>b.id?1:-1).map((category, i) => {
        return (
          <li
            className="category-list-item clearfix"
            key={`category-item-${i}`}
            onClick={(e) => {
              try {
                navigation.navigate(`/judge/category/${category.id}`);
              } catch (error) {
                console.log("COULD NOT NAVIGATE");
                console.log(error);
              }
            }}
          >
            {/*<div className="category-submission-info" onClick={(e)=>{try{navigation.navigate(`/judge/category/${data.category.id}`)}catch(error){console.log('COULD NOT NAVIGATE');console.log(error)}}}>*/}
            {/*    {score.getTotalScored(data.submissions)}/{data.submissions.length}*/}
            {/*    <span>SCORED</span>*/}
            {/*</div>*/}
            <div className="category-list-info">
              <div className="category-title">
                {category[lang.toLowerCase() === "fr" ? "title_fr" : "title"]}
              </div>
              {/*<div className="category-description"><span className="label">Definition:</span><span dangerouslySetInnerHTML={{__html: category.description}}/></div>*/}
            </div>
            <div className="category-list-action">
              <span className="sprite large-icon arrow-right black" />
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default CategoryTable;

import React from "react";

export default ({
  label,
  error,
  type,
  name,
  value,
  placeholder,
  onInputChange,
  id,
}) => {
  // console.log('INPUT GROUP')
  // console.log(label)
  // console.log(error)
  return (
    <div className="input-group">
      <label htmlFor="username">{label}: </label>
      <span className="error">{error}</span>
      <input
        id={id}
        onChange={onInputChange}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

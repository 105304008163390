import judgeEntries from "./temp_judgeEntries";
const mandate_length = 300;
const s3_url = "https://nmafpublic.s3.ca-central-1.amazonaws.com/";
export const score = {
  getData() {
    return judgeEntries;
  },

  processJudgeDataForSummary({ category, submissions, max }) {
    // console.log("processJudgeDataForSummary");
    // console.log({ category, submissions, max });
    const d = submissions
      .slice(0, max || submissions.length)
      .map((entry, i) => {
        return {
          favourite: this.getSubmissionStar(entry.favourite),
          submission: this.getSubmissionInfo(entry),
          publication: this.getPublicationInfo(entry),
          score: this.getScoreInput({
            category_id: category.id,
            scores: category.scores,
            value: entry.score,
            entry_id: entry.id,
            score_id: entry.score_id,
          }),
          media: this.getSubmissionMedia(entry),
          id: entry.id,
          entry,
        };
      });
    return d;
  },

  getSubmissionStar(favourite) {
    return `<span class="sprite favourite-icon icon black star-${
      favourite ? `filled` : `outline`
    }"><span>${favourite ? 1 : 0}</span></span>`;
  },

  getSubmissionThumb(img) {
    // console.log('getSubmissionThumb')
    // console.log(img)
    //https://s3.ca-central-1.amazonaws.com/nma.public/submission/2018/001205a0-2ac7-46f0-9fb0-81f52e32a34e.png
    if (img)
      return `<img class="submission-thumbnail" src="${
        s3_url + img.thumbnail
      }" alt="Thumbnail for... "/>`;
    return "";
    // gm(img+'[0]').setFormat('jpg').resize(200).quality(75).write('/tmp/file0.jpg',(error)=>{
    //     console.log("in Write")
    //     if(error) {
    //         console.log('error')
    //         console.log(error)
    //     } else {
    //         console.log('SAVED');
    //     }
    // })
  },

  getSubmissionMedia(entry) {
    // console.log('MEDIA');
    // console.log(entry);
    if (!entry) return `(No Submission)`;
    if (!entry.media) return `(No Media)`;
    return `
            <a href="${
              entry.media.find((e) => e.type === "pdf").url
            }" target="_blank"><span class="sprite large-icon black pdf"></span></a>
        `;
  },

  getSubmissionInfo(entry) {
    // console.log("getSubmissionInfo()", entry);
    const title = entry.title;
    const mediaFields = entry.fields
      ? entry.fields.filter((a) => a.type === "pdf" && !!a.file)
      : []; //TODO: Switch to Filter

    // console.log("getSubmissionInfo MEDIA")
    // console.log(mediaFields)
    if (!entry) return `(No Submission Info)`;
    else {
      return `
                <div class="submission-info">
                    <div class="submission-title">${
                      (title && title.value) || "(No Title)"
                    }</div>
                    <div class="submission-media clearfix">
                    ${mediaFields.map((field, i) => {
                      const k = field.file.key;
                      return `<a class="btn table-btn" herf="${
                        k.indexOf("https") === 0 ? k : s3_url + k
                      }" target="_blank">Download File ${
                        mediaFields.length > 1
                          ? `${i + 1} of ${mediaFields.length}`
                          : ""
                      }</a>`;
                    })}
                        <div 
                        class="submission-thumbnail" 
                        style="background-image:url(${
                          mediaFields.length > 0
                            ? s3_url + mediaFields[0].file.thumbnail
                            : ""
                        })">&nbsp;</div>
                        
                    </div>
                </div>
            `;
    }
  },

  /*
    <div class="submission-download">
                            ${media.map(file=>{
                                console.log('filefilefile')
                                console.log(file)
                                return `<a class="btn table-btn" herf="${s3_url + file.key}">Download File</a>`
                            })}
                        </div>
     */

  //                    ${entry.creator?`<div class="submission-creator table-more-info"><span>Created by:</span> ${entry.creator}</div>`:``}

  getPublicationInfo(entry) {
    const publication = entry.publication;
    const mandate = entry.fields
      ? entry.fields.find((a) => {
          if (!a.title) return false;
          return a.title.toLowerCase().indexOf("editorial mandate") >= 0;
        })
      : [];
    // console.log('MANDATE')
    // console.log(mandate)
    if (!publication) return `(No Publication Info)`;
    else {
      return `
                <div class="publication-info">
                    <div class="publication-title">${
                      publication.value || "(No Publisher Listed)"
                    }</div>
                    ${
                      mandate.value
                        ? `<div class="publication-mandate table-more-info"><span>Editorial Mandate:</span> ${mandate.value.substr(
                            0,
                            Math.min(mandate_length, mandate.value.length)
                          )}
                        ${
                          mandate.value.length > mandate_length
                            ? `<div class="expand-mandate btn table-btn" data-mandate="${mandate.value}">Read More</div>`
                            : ``
                        }</div>`
                        : ``
                    }
                </div>
            `;
    }
  },

  getTotalScored(data) {
    if (!data) return `(No Score Data)`;
    let count = 0;
    data.map((submission) => {
      let isComplete = true;
      submission.score.map((score) => {
        if (score.value === null) isComplete = false;
      });

      count += isComplete ? 1 : 0;
    });

    return count;
  },

  setScoreValue(scoreData) {
    // console.log(scoreData)
    const score = judgeEntries
      .find((e) => e.category.id === scoreData.catid)
      .submissions.find((e) => e.id === scoreData.subid)
      .score.find((e) => e.id == scoreData.scoreid);

    score.value = parseInt(scoreData.value);

    // judgeEntries.filter(v=>{
    //     console.log('v')
    //     console.log(v)
    // })
  },

  // REMOVED: Using React Components for this purpose
  // may not be optimized for the react-table use —-> renders as "[object Object]"
  //
  // getScoreInput(data){
  //     if(!data) return `(No Score Data)`;
  //     let scoreBody = data.map((score,i)=>
  //         <li className="score-item input-group">
  //             <label htmlFor={`score_${score}`}>{score.title}</label>
  //             <input type="text" className="score-change" id="score_${score.id}" data-id={score.id} value={score.value?score.value:``} placeholder="Enter your score.."/>
  //             <p className="score-scale-note">Scoring scale: {score.min}-${score.max}<br/>Use "C" to indicate a conflict of interest</p>
  //         </li>
  //     )
  //
  //     return <ul class="score-input-list">{scoreBody}</ul>;
  // },

  getScoreInput({ scores, value, entry_id, score_id }) {
    // console.log('getScoreInput')
    // console.log(scores)
    if (!scores) return `(No Score Data)`;
    let scoreBody = scores.map(
      (score) => `
            <li class="score-item input-group">
                <div class="error"></div>
                <label>
                <span class="label">${score.label}</span>
                <input 
                    type="text" 
                    class="score-change" 
                    id="score_${score.id}_${entry_id}" 
                    data-score="${score_id}" 
                    data-entry="${entry_id}" 
                    data-ref="${score.id}" 
                    value="${value ? value : ""}" 
                    placeholder="${score.label}"/>
                </label>
                <div class="clearfix state-wrap">
                    <div class="unsaved">Not Saved</div>
                    <div class="success">Saved</div>
                </div>
               
            </li>`
    );

    // <label htmlFor="score_${score}">${score.title}</label>

    return [`<ul class="score-input-list">`]
      .concat(scoreBody, [`</ul>`])
      .join("");
  },

  // THIS GET SCORE INPUT WAS REPALCED BY
  // A TEXT INPUT FIELD.
  // getScoreInput(data){
  //     if(!data) return `(No Score Data)`;
  //     else{
  //         let scoreBody = data.map((score,i)=>`
  //         <li class="score-item input-group select-wrap">
  //             <label for="score_${score}">${score.title}</label>
  //             <select  class="score-change" id="score_${score.id}" data-id="${score.id}">
  //                             <option value={-1}> Not Scored</option>
  //                             <option disabled>──────────</option>
  //                             ${Array.from({length:Math.round(score.max-score.min)+1},(k,v)=>v+score.min).map((val,i)=>(
  //                                 `<option id="score-option-${i}" value=${val} ${score.value===val?`selected`:``}>${val}</option>`
  //                             ))}
  //                             <option disabled>──────────</option>
  //                             <option value="c">C — Conflict</option>
  //
  //                         </select>
  //         </li>
  //        `);
  //         //defaultValue={score.value===null?-1:score.value}
  //         return [`<ul class="score-input-list">`].concat(scoreBody,[`</ul>`]).join('');
  //     }
  // },
  getScore(data) {
    if (!data) return `(No Score Data)`;
    else {
      let scoreBody = data.map(
        (score) => `
            <li class="score-item"><span class="score-label">${
              score.title
            }</span>: <span class="score-value">${
          score.value ? `${score.value}` : `&ndash;`
        }/${score.max}</span></li>
           `
      );

      return [`<ul class="score-list">`].concat(scoreBody, [`</ul>`]).join("");
    }
  },
};

export default score;

import createAuthRefreshInterceptor from "axios-auth-refresh";
import authService from "../api/auth";

const refreshAuthLogic = (errorHandler) => (failedRequest) =>
  authService
    .refresh()
    .then(() => {
      // console.log("Refresh Accepted...");
      return Promise.resolve();
    })
    .catch((e) => {
      console.log("Refresh Auth Logic Error", {
        message: e.message,
        failedRequest,
      });
      if (typeof errorHandler === "function") errorHandler(e);
      return Promise.reject(e);
    });

const refresh = (client, errorHandler, options) => {
  return createAuthRefreshInterceptor(
    client,
    refreshAuthLogic(errorHandler),
    options
  );
};

export default refresh;

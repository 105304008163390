import React from "react";
import ScoreCardTable from "./Table/ScoreCardTable";
import { useNavigation, Link } from "react-navi";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import api from "../../api/judgeController";
import LoadingIcon from "../Common/LoadingIcon";
import { Typography } from "@material-ui/core";
import useJudgeEntries from "../../hooks/useJudgeEntries";


const category_text = {
  en: {
    body: {
      loading: "Loading...",
    },
    btn: {
      saveGoBack: "Save & Go Back",
      downloadAll: "Download All Submissions",
      downloading: "Downloading...",
    },
  },
  fr: {
    body: {
      loading: "Téléchargement ...",
    },
    btn: {
      saveGoBack: "Enregistré et retour",
      downloadAll: "Téléchargez tous les PDFs",
      downloading: "Téléchargement...",
    },
  },
};

function Category({ categoryId, org, lang, user }) {
  let navigation = useNavigation();
  const [isLoading, setLoading] = React.useState(false);


  const {
    judgeEntries,
    setJudgeEntries,
    loading: loadingJudgeEntries,
  } = useJudgeEntries({ user, categoryId });


  const saveJudgeScores = React.useCallback(
    (callback) => {
      setLoading(true);
      api
        .saveJudgeEntries(judgeEntries)
        .then((res) => {
          setLoading(false);
          callback && callback(res);
        })
        .catch((err) => {
          console.log("Category() saveJudgeScores() error", err.message);
          callback && callback(err);
        });
    },
    [judgeEntries]
  );

  const handleSaveAndBack = React.useCallback(async () => {
    saveJudgeScores(() => navigation.navigate(`/judge/category/`));
  }, [navigation, saveJudgeScores]);

  const uploadUrls = judgeEntries
    ?.map((judgeEntry) => {
      return judgeEntry?.fields
        ?.filter((field) => field.type === "upload")
        ?.map((field) => {
          console.log("URL:", field.value);
          return field.value;
        });
    })
    ?.flat();
  console.log("uploadUrls", uploadUrls);
  return (
    <>
      {isLoading ? (
        <>
          <LoadingIcon /> {category_text[lang].body.loading}
        </>
      ) : (
        <>
          <div className={"cols padded-row"}>
            <div className="col m">
              <h2 id="submission_information_intro">
              </h2>
              <Typography variant={"subtitle1"}>
                Enter your total score between (max. 30) below. Use a "C" to
                mark as a conflict of interest.
              </Typography>
              <br />
              <div className="text-right">
                <Link
                  href={`https://api.mediafoundation.ca/api/admin/download/exportCategories/${categoryId}`}
                  target={"_blank"}
                  className="btn table-btn"
                >
                  <CloudDownload style={{ marginRight: "8px" }} /> Download All
                  Files
                </Link>
                
                &nbsp;
                <Link onClick={handleSaveAndBack} className="btn table-btn">
                  <ArrowBack width={8} />{" "}
                  {category_text[lang.toLowerCase()].btn.saveGoBack}
                </Link>
              </div>
            </div>
          </div>
          <div className="category-score-table">
            {!loadingJudgeEntries &&
              judgeEntries &&
              judgeEntries.length > 0 && (
                <ScoreCardTable
                  submissions={judgeEntries}
                  lang={lang}
                  org={org}
                  setSubmissions={setJudgeEntries}
                />
              )}
            {!loadingJudgeEntries &&
              judgeEntries &&
              judgeEntries.length === 0 && (
                <p>
                  You do not have any entries for this category.{" "}
                  <Link href={"/judge/dashboard"}>
                    Go back to your Dashboard
                  </Link>
                </p>
              )}

            <div className="text-right">
              <Link onClick={handleSaveAndBack} className="btn table-btn">
                <ArrowBack width={8} />{" "}
                {category_text[lang.toLowerCase()].btn.saveGoBack}
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Category;

import { useState, useEffect, useCallback } from "react";
import api from "../api/judgeController";

//{ t = {}, org }
function useJudgeEntries({ user, categoryId }) {
  const [judgeEntries, setJudgeEntries] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchJudgeEntries = useCallback(() => {
    console.log('fetchJudgeEntries()')
    if (user.token) {
      setLoading(true);

      api
        .getJudgeSubmissions(categoryId)
        .then((data) => {
          console.log('fetchJudgeEntries() RES',data)
          setLoading(false);
          if (data) setJudgeEntries([...data]);
        })
        .catch((e) => {
          setLoading(false);
          console.log(
            "Failed to Fetch Judge Entries. useJudgeEntries()",
            e.message
          );
        });
    }
  }, [user.token, categoryId]);

  useEffect(() => {
    fetchJudgeEntries();
  }, [fetchJudgeEntries]);

  return { judgeEntries, setJudgeEntries, loading };
}

export default useJudgeEntries;

import { client } from "../api/client";

export default {
  getJudgeSubmissions: async (categoryId) => {
    try {
      const req = await client.get("/judge/category/" + categoryId);
      console.log('getJudgeSubmissions() req',req)
      return req.data;
    } catch (e) {
      console.log("Could not get Judge Submissions");
      return false;
    }
  },
  exportJudgeEntries: async (categoryId) => {
    try {
      const req = await client.post("/judge/category/download/export.zip", {
        categoryId,
        other: "data",
      });
      console.log("exportJudgeEntries() DATA");
      console.log(req);

      return req.data;
    } catch (e) {
      console.log("Could not get Judge Submission PDFs");
      return false;
    }
  },
  saveJudgeEntry: async (judgeEntry) => {
    try {
      // console.log("saveJudgeEntry()");
      const req = await client.post(`/judge/judgeEntry/${judgeEntry.id}`, {
        judgeEntry,
      });

      return req.data;
    } catch (e) {
      console.log("Could not update Judge Submission", e.message);
      return false;
    }
  },
  saveJudgeEntries: async (judgeEntries) => {
    try {
      // console.log("saveJudgeEntries()");
      const reqs = judgeEntries.map(async (judgeEntry) => {
        return await client.post(`/judge/judgeEntry/${judgeEntry.id}`, {
          judgeEntry,
        });
      });
      return await Promise.all(reqs);
    } catch (e) {
      console.log("Could not update Judge Submissions", e.message);
      return false;
    }
  },
};

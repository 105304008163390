import React from "react";
import { lazy, map, mount, redirect, route } from "navi";
import Login from "../Components/Login/Login";
import Dashboard from "../Components/Judge/Dashboard";
import Category from "../Components/Judge/Category";
import Submissions from "../Components/Judge/Submissions";
import Score from "../Components/Judge/Score";

import judgeEntries from "./temp_judgeEntries";
import api from "../api/judgeController";
import ChangePassword from "../Components/Login/ChangePassword";
import ResetPassword from "../Components/Login/ResetPassword";

const DEFAULT_REDIRECT = "/judge/dashboard/";
const pageTitles = {
  en: {
    login: "Login",
    register: "Register your Account",
    resetPassword: "Reset your Password",
    dashboard: "Judging Dashboard",
    category: "Judge Category",
    cart: "Your Entries",
    payment: "Payment",
    profile: "Your Submissions Profile",
  },
  fr: {
    login: "Connexion",
    register: "Créer un compte",
    resetPassword: "Réinitialisez votre mot de passe",
    dashboard: "Portail d'évaluation",
    category: "Catégorie",
    entry: "Soumettre une candidature",
    edit: "Modifier une candidature",
    cart: "Paiement de vos candidatures",
    payment: "Paiement",
    profile: "Vos candidatures",
  },
};

const checkEmptyTokenFromContext = (context) => (!context.token || context.token === "")

const routes = mount({
  "*": map((req, context) => {
    if (checkEmptyTokenFromContext(context)) {
      return redirect(`/login`, { exact: false }); //?redirectTo=${encodeURIComponent(req.originalUrl)} &lang=${lang}
    } else {
      return mount({
        "/judge": mount({
          "/": redirect(DEFAULT_REDIRECT),
          "/dashboard": map((req, context) => {
            return route({
              title: pageTitles[context.lang.toLowerCase()].dashboard,
              view: <Dashboard {...context} />,
            });
          }),
          "/submissions": map((req, context) => {
            return route({
              title: "Submissions",
              view: <Submissions {...context} />,
            });
          }),
          "/score": mount({
            "/": redirect("/judge/submissions"),
          }),
          "/category": mount({
            "/": redirect("/judge/dashboard?from=home"),
            "/:id": map((req, context) => {
              return route({
                title: pageTitles[context.lang.toLowerCase()].category,
                view: <Category categoryId={req.params.id} {...context} />,
              });
            }),

          }),
          "/forceReset":mount({
            "/": map((req, context) => {
              return route({
                title: pageTitles[context.lang].resetPassword,
                view: <ChangePassword {...context} />,
              });
            }),


          }),
        }),
      });
    }
  }),
  "/": redirect("/login"),
  "/login": map((req, context) => {
    if (checkEmptyTokenFromContext(context)) {
      return route({
        title: pageTitles[context.lang].login,
        view: <Login {...context} />,
      });
    } else {
      return redirect(DEFAULT_REDIRECT);
    }
  }),
  "/resetPassword": map((req, context) => {
    return route({
      title: pageTitles[context.lang].resetPassword,
      view: <ResetPassword {...context} />,
    });
  }),

  "/changePassword": map((req, context) => {
    const {resetToken} = req.params
    console.log('ROUTE CHANGE PASSWORD',{req})
    return route({
      title: pageTitles[context.lang].resetPassword,
      view: <ChangePassword {...context} {...{resetToken}}  />,
    });
  }),
});

export default routes;

import React, { useEffect, useState } from "react";
import InputGroup from "../Judge/InputGroup";
import { useNavigation } from "react-navi";

const ChangePassword = ({ authService, lang, org, resetToken }) => {
  const [sendingForm, setSendingForm] = useState(false);
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    cur_password: "",
    new_password: "",
    rep_password: "",
    token: resetToken,
  });

  let navigation = useNavigation();
  const changePassword = async () => {
    if (!formData.token && formData.cur_password.trim().length === 0)
      setFormError("Please enter your current password.");
    else if (formData.new_password.trim().length < 6)
      setFormError("New password must be at least 6 characters.");
    else if (formData.new_password !== formData.rep_password)
      setFormError("Your passwords do not match.");
    else {
      setFormError("");
      const doPasswordChange = await authService.changePassword({
        password: formData.new_password,
        token: formData.token,
        lang,
        org,
      });
      console.log("doPasswordChange()", doPasswordChange);
      if (!doPasswordChange)
        setFormError("Could not reset password. Please try again.");
      else await navigation.navigate("/judge/dashboard");
    }

    setSendingForm(false);

    return false;
  };

  return (
    <form>
      {formError.length > 0 && (
        <div className={"error"}>
          <p className={"error"}>{formError}</p>
        </div>
      )}
      <h1>Reset Your Password</h1>
      {/*<p>Passwords must be at least six characters long.</p>*/}
      {!formData.token && (
        <InputGroup
          id="reset_current"
          onInputChange={(e) => {
            e.persist();
            setFormData((prev) => ({ ...prev, cur_password: e.target.value }));
          }}
          value={formData.cur_password}
          type="password"
          label="Current Password"
          name="cur_password"
          placeholder="••••••"
        />
      )}
      <InputGroup
        id="reset_new"
        onInputChange={(e) => {
          e.persist();
          setFormData((prev) => ({ ...prev, new_password: e.target.value }));
        }}
        value={formData.new_password}
        type="password"
        label="New Password"
        name="new_password"
        placeholder="••••••"
      />
      <InputGroup
        id="reset_rep"
        onInputChange={(e) => {
          e.persist();
          setFormData((prev) => ({ ...prev, rep_password: e.target.value }));
        }}
        value={formData.rep_password}
        type="password"
        label="Repeat New Password"
        name="rep_password"
        placeholder="••••••"
      />
      <div className="text-right btn-wrap">
        <button
          id="go_back"
          type="button"
          className="btn btn-secondary"
          onClick={(e) => {
            e.preventDefault();
            navigation.navigate(`/login`);
          }}
        >
          Back to Login
        </button>
        <button
          id="submit"
          type="button"
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            if (!sendingForm) {
              setSendingForm(true);
              changePassword();
            }
          }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;

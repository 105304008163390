import React from "react";
import ScoreCardTable from "./Table/ScoreCardTable";
import judgeEntries from "../../utils/temp_judgeEntries";

function Submissions({ authService, user }) {
  // console.log('DASH AUTH')
  // console.log(authService)
  // console.log(authService.isAuthenticated())

  //TODO Do i need to check authService.isAuthenticated() --> Program may auto detect and return to login. Might need to be check intraservice (i.e. sending/receiving new data)
  return (
    <>
      <div className={"cols"}>
        <div className="col l">
          <h2 id="submission_information_intro">All Submissions</h2>
          <p className="intro-text">
            Click on any row in the table below to review and score the
            submission
          </p>
        </div>
        <div className="col s text-right">
          <a className="btn table-btn" href="/judge/dashboard">
            <strong>&#8592;</strong> Dashboard
          </a>
        </div>
      </div>

      <div className="dashboard-score-card">
        {/*{*/}
        {/*   judgeEntries.map(category => <CategoryTable data={category}/>)*/}
        {/*}*/}
        {/*<h3>Score Card Overview</h3>*/}
        {/*<ScoreCardTable token={user.token} userId={user.userId} />*/}
      </div>
    </>
  );
}

export default Submissions;
